
import { QuestionOption } from "@/api/LumediApi";
import Checkbox from "primevue/checkbox";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: String,
			required: false,
		},
		options: {
			type: Array as () => QuestionOption[],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Checkbox,
	},
	computed: {
		localValue: {
			get(): string[] | undefined {
				if (this.value === undefined) return undefined;

				return this.value.split("|");
			},
			set(value: string[] | undefined) {
				if (value === undefined) {
					this.$emit("input", undefined);
					return;
				}

				const formattedValue = value.filter((v) => v !== "").join("|");
				this.$emit("input", formattedValue);
			},
		},
	},
});
